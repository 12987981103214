import Parse from 'parse';
import React, { useEffect, useState } from "react";
import { NavLink as RouteNavLink, withRouter } from "react-router-dom";
import { Collapse, DropdownItem, DropdownMenu, NavItem, NavLink } from "shards-react";
import { Constants, Dispatcher } from "../../../flux";
import { ReactComponent as AdminIcon } from "../../../images/admin.svg";
import { ReactComponent as AiIcon } from "../../../images/ai.svg";
import { ReactComponent as EnterpriseIcon } from "../../../images/enterprise_icon.svg";
import { ReactComponent as EvaluateIcon } from "../../../images/evaluate.svg";
import { ReactComponent as IdeasIcon } from "../../../images/ideas.svg";
import { ReactComponent as ImplementIcon } from "../../../images/implement.svg";
import { ReactComponent as ReportsIcon } from "../../../images/reports.svg";
import { ReactComponent as RewardIcon } from "../../../images/reward.svg";
import { ReactComponent as SubmitCheckIcon } from "../../../images/submit-check.svg";
import { ReactComponent as VerifyIcon } from "../../../images/verify.svg";
import { authUser } from "../../../services/userService";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const SidebarNavItem = (props) => {
  const { item } = props;
  const [showDuplicateTab, setShowDuplicateTab] = useState(false);
  const isMobile = useIsMobile();
  
  useEffect(() => {
    const fetchConfiguration = async () => {
      const cachedShowDuplicateTab = localStorage.getItem('showDuplicateTab');
      if (cachedShowDuplicateTab !== null && cachedShowDuplicateTab !== undefined && cachedShowDuplicateTab !== 'undefined') {
        if(cachedShowDuplicateTab){
          setShowDuplicateTab(JSON.parse(cachedShowDuplicateTab));
        }
      } 
      try {
        const query = new Parse.Query("Configuration");
        const config = await query.first();
        const showDuplicateTab = config ? config.get("showDuplicateTab") : false;

        setShowDuplicateTab(showDuplicateTab);
        localStorage.setItem('showDuplicateTab', JSON.stringify(showDuplicateTab));
      } catch (error) {
        console.error("Error fetching configuration:", error);
      }
    };

    fetchConfiguration();
  }, []);

  const toggleDropdown = (item) => {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR_DROPDOWN,
      payload: item
    });
  };

  const getCorrectIcon = (name) => {
    switch (name) {
      case "dashboard":
        return <IdeasIcon style={{ height: 10, width: 50 }} className="mr-2" />;
      case "admin":
        return <AdminIcon style={{ height: 15, width: 50 }} className="mr-2" />;
      case "evaluate":
        return <EvaluateIcon style={{ height: 15, width: 50 }} className="mr-2" />;
      case "ideas":
        return <SubmitCheckIcon style={{ height: 15, width: 50 }} className="mr-2" />;
      case "implement":
        return <ImplementIcon style={{ height: 15, width: 50 }} className="mr-2" />;
      case "reports":
        return <ReportsIcon style={{ height: 15, width: 50 }} className="mr-2" />;
      case "enterprise":
        return <EnterpriseIcon style={{ height: 15, width: 50 }} className="mr-2" />;
      case "reward":
        return <RewardIcon style={{ height: 15, width: 50 }} className="mr-2" />;
      case "verify":
        return <VerifyIcon style={{ height: 20, width: 50 }} className="mr-2" />;
      default:
        return <></>;
    }
  };

  const handleNavigation = (to, hasSubItems) => {
    if (!hasSubItems) {
      if(window.location.pathname !== to)
        props.history.push(to);
      else
        window.location.reload();
    }
  };

  const handleNavigationTo = (to) => {
    if(to === '/evaluate')
      window.location.href ='/evaluate';
    else{
      if( window.location.pathname === to)
        window.location.reload();
      else
        props.history.replace(to);
    }
  };

  const renderItems = (items) => {
    const currentUser = authUser();

    return items.filter(item => !isMobile || item.mobile).map((item, idx) => {
      const hasSubItems = item.items && item.items.length;

      if (!currentUser.company.isEnterprise && item.to === '/enterprise-r1') return null;

      return (
        <NavItem key={idx} style={{ position: "relative" }}>
          <NavLink
            className={hasSubItems ? "dropdown-toggle" : ""}
            tag={hasSubItems ? "a" : RouteNavLink}
            to={hasSubItems ? "#" : item.to}
            onClick={() => {
              toggleDropdown(item);
              handleNavigation(item.to, hasSubItems);
            }}
          >
            {item.iconName && getCorrectIcon(item.iconName)}
            {item.title && <span>{item.title}</span>}
           
          </NavLink>
          {hasSubItems && (
            <Collapse tag={DropdownMenu} small open={item.open} style={{ top: 0 }}>
              {item.items.map((subItem, subIdx) => {
                if (subItem.to === "/evaluate" && showDuplicateTab) {
                  subItem.title = <>Evaluate<span style={{marginLeft: '5px'}}><AiIcon width={18} /><small style={{marginLeft: '5px'}}>Beta</small> </span></>;
                } 

                return (
                  <DropdownItem 
                    key={subIdx} 
                    tag={RouteNavLink}
                    to={`#`}
                    onClick={() => handleNavigationTo(subItem.to)} >
                    {subItem.title}
                  </DropdownItem>
                );
              })}
            </Collapse>
          )}
        </NavItem>
      );
    });
  };

  return (
    <>
      {renderItems([item])}
    </>
  );
};

export default withRouter(SidebarNavItem);
