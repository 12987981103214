import moment from 'moment';
import Parse from 'parse';
import React from "react";
import {
  Col,
  DatePicker,
  Form,
  FormInput,
  Row
} from "shards-react";

import { object } from "prop-types";
import { users as UserListNew } from '../../components/components-overview/UserListNew';


// Defult
import { ReactComponent as AddNextStepIcon } from "../../images/add_next_step.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as GreenIcon } from "../../images/green_icon.svg";
import { ReactComponent as HandImage } from "../../images/hand.svg";
import { ReactComponent as ChartImage } from "../../images/line-chart.svg";
import { ReactComponent as NotSelectedTaskIcon } from "../../images/not_selected_task.svg";
import { ReactComponent as RedIcon } from "../../images/red_icon.svg";
import { ReactComponent as SelectedTaskIcon } from "../../images/selected_task.svg";
import { ReactComponent as ShieldImage } from "../../images/shield.svg";
import { ReactComponent as TeamImage } from "../../images/team.svg";
import { ReactComponent as TimeImage } from "../../images/time.svg";
// fill="#157ffb"

// New
import { ReactComponent as UrgentImage } from '../../images/Icons_Idle_01_Urgent.svg';
import { ReactComponent as ProductivityImage } from "../../images/Icons_Idle_02_Productivity.svg";
import { ReactComponent as TrophyImage } from "../../images/Icons_Idle_04_Trophy.svg";
import { ReactComponent as Shield2Image } from "../../images/Icons_Idle_05_Shield.svg";
import { ReactComponent as DollarSignImage } from "../../images/Icons_Idle_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImage } from "../../images/Icons_Idle_07_Number One.svg";
import { ReactComponent as CheckmarkImage } from "../../images/check1.svg";
// import selectIdeaImage from '../../images/select_idea_category_new.png';
import selectIdeaImage from '../../images/selected.png';

// New Selected
import { ReactComponent as UrgentImageSelected } from '../../images/Icons_Selected_01_Urgent.svg';
import { ReactComponent as ProductivityImageSelected } from "../../images/Icons_Selected_02_Productivity.svg";
import { ReactComponent as TrophyImageSelected } from "../../images/Icons_Selected_04_Trophy.svg";
import { ReactComponent as Shield2ImageSelected } from "../../images/Icons_Selected_05_Shield.svg";
import { ReactComponent as DollarSignImageSelected } from "../../images/Icons_Selected_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImageSelected } from "../../images/Icons_Selected_07_Number One.svg";
import { ReactComponent as CheckmarkImageSelected } from "../../images/check1_selected.svg";

// Import Category Icons
import UrgentImageLocal from '../../images/Icons_Idle_01_Urgent.svg';
import ProductivityImageLocal from "../../images/Icons_Idle_02_Productivity.svg";
import TrophyImageLocal from "../../images/Icons_Idle_04_Trophy.svg";
import Shield2ImageLocal from "../../images/Icons_Idle_05_Shield.svg";
import DollarSignImageLocal from "../../images/Icons_Idle_06_Dollar Sign.svg";
import NumberOneImageLocal from "../../images/Icons_Idle_07_Number One.svg";
import CheckmarkImageLocal from "../../images/check1.svg";

import { createTask, deleteTaskById, getTasksByOpportunityId, updateTaskById } from '../../services/implementService';



import { withTranslation } from 'react-i18next';
import { authUser } from '../../services/userService';


const images = [
  {
    original: UrgentImageLocal,
    thumbnail: UrgentImageLocal,
    name: 'Urgent'
  },
  {
    original: ProductivityImageLocal,
    thumbnail: ProductivityImageLocal,
    name: 'Productivity'
  },
  {
    original: CheckmarkImageLocal,
    thumbnail: CheckmarkImageLocal,
    name: 'Checkmark'
  },
  {
    original: TrophyImageLocal,
    thumbnail: TrophyImageLocal,
    name: 'Trophy'
  },
  {
    original: Shield2ImageLocal,
    thumbnail: Shield2ImageLocal,
    name: 'Shiel Image'
  },
  {
    original: DollarSignImageLocal,
    thumbnail: DollarSignImageLocal,
    name: 'Dollar Sign'
  },
  {
    original: NumberOneImageLocal,
    thumbnail: NumberOneImageLocal,
    name: 'Number One'
  },
];


const remCharStyle = {
  color: 'green'
};

class NextStepsForm extends React.Component {
    constructor(props) {
        super(props);

        // Refs
        this.galleryRef = React.createRef();

        this.state = {
          data:[],
          ideaQuestions: [],
          answers:[],
          category:null,
          department:null,
          date: '',
          categoryQuestions: [],
          filterQuestions: [],
          selectedFilterQ: [],
          filterQAnswers: [],
          categoryQAnswers: [],
          ideaDescription: null,
          file:null, 
          buttonNext:true,
          descriptionValid:'',
          remainingCharacters: 250,
          visible: true,
          filterVisible: false,
          filterQuestionsVisible: false, 
          ideaQuestionsVisible: false,
          hideNextButton: false,
          userName: 'User Name',
          sectionTitle:'',
          formButtonTitle: 'Continuar',
          ideaNumber: '#00008',
          status: '',
          executionResObj: object,
          selectedBenefit:'',
          selectedResult: '',
          money: '',
          selectedImprovement: '',
          selectedImpact: '',
          selectedCoachBackup: '',
          isRecognized: '',
          page: 2,
          responseInfo: false,
          responseInfo2: false,
          selectedStatus: ''.length,
          expectedReturn: '',
          timeUnit: '',
          language: 'en',
          executionRes: 0,
          coachRes: '',
          recurringImpact: false,
          comment: '',
          categoryDuration: false,
          startDate: '',
          endDate: '',
          selectedLanguage: {
            value:'English',
            label:'English'
          }, 
          categoryTitle: '',
          categoryInformation: '',
          hasEnglish: false,
          hasSpanish: false,
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          privileges: [],
          userData: '',
          canEdit: '',
          accountDisabled: false,
          taskData: [],
          activeSteps: 0
        }

        this.change = this.change.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setIdeaDescription = this.setIdeaDescription.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.showNext = this.showNext.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
        this.saveIdea = this.saveIdea.bind(this);
        this.showNext = this.showNext.bind(this);
        this.changeMoney = this.changeMoney.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.setEvalStatus = this.setEvalStatus.bind(this);
        this.setFirstName = this.setFirstName.bind(this);
        this.setEmail = this.setEmail.bind(this);
        // this.setPassword = this.setPassword.bind(this);
        this.setLanguage = this.setLanguage.bind(this);

    }

    

    componentDidUpdate(prevProps) {
      const {userData} = this.props;
    
      if (prevProps.key != this.props.key) {
        // alert(prevProps.reloadData)
        // this.loadInitialSettings()
        // alert("SUbe")
      }
    }

    componentDidMount() {

      // Category
    //   this.loadInitialSettings()

      let currUser = Parse.User.current();
      
      this.getUserName(currUser);
      // this.fetchNewData();
      
      this.fetchNextSteps();

      // this.fetchFilterQuestions();
      // this.getDate();
    }
   
    loadInitialSettings() {
      const { userData, canEdit } = this.props;
       
      if (userData) {
        const firstName = userData.get("firstName")
        const lastName = userData.get("lastName")
        const email = userData.get("username")
        const accountDisabled = userData.get("accountDisabled")

        const isSuperUser = userData.get("role") === 'super_user' || userData.get("role") === 'murmuratto'
        const isCoach = userData.get("coach")
        const isSupervisor = userData.get("isSupervisor")

        const isPmo = userData.get("pmo")
        const isVerification = userData.get("verification")
        const isReward = userData.get("reward")

        const roles = []
        const privileges = []

        // Roles

        if (isSuperUser) {
          roles.push({value:"super_user", label:'Super User'})
        }

        if (isCoach) {
          roles.push({value:"coach", label: 'Coach'})
        }

        if (isSupervisor) {
          roles.push({value:"supervisor", label: 'Supervisor'})
        }

        // Privileged

        if (isPmo) {
          privileges.push({value:"pmo", label:'PMO'})
        }

        if (isVerification) {
          privileges.push({value:"verification", label: 'Verification'})
        }

        if (isReward) {
          privileges.push({value:"reward", label: 'Reward & Recognitio'})
        }
        
        const department = userData.get("department")
        const selectedDepartment = {value: "", label: department}

        this.setState({firstName: firstName, lastName: lastName, email: email, department: selectedDepartment, roles: roles, privileges: privileges, canEdit: true, userData: userData, accountDisabled: accountDisabled})
      }


      // this.setState({categoryOn: categoryStatus, categoryTitle: categoryName, categoryInformation: categoryInformation, hasEnglish: hasEnglish, hasSpanish: hasSpanish})
    }

    getDate() {
      this.setState({
        date: new Date()
      });
    }

    async getUserName(user) {
      const myUser = authUser();
      const fullName = myUser.fullName;
      this.setState({
        userName: fullName
      });
    }

    handleSubmit() {
      const { formButtonTitle } = this.state;
      
      if (formButtonTitle == "Continuar") {
        this.showNext();
      } else {
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas requeridas.");
        } else {
        alert("Su IDEA ha sido sometida.");
      }
    }
  }

    showNext() {
      const isShowingForm = this.state.visible;
      const isShowingFilter = this.state.filterVisible;
      const isShowingFilterQuestions = this.state.filterQuestionsVisible;
      const isShowingQuestions = this.state.ideaQuestionsVisible;

      if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

        const { category, department, descriptionValid } = this.state;

        if (!category || !department || !descriptionValid) {
          alert("Por favor seleccione una categoria, un departamento y asegurese de que la descripcion de la idea sea valida.");
        } else {
          this.setState({
            visible: !isShowingForm,
            filterVisible: !isShowingFilter,
            buttonState: false,
            hideNextButton: true,
          });
        }
      } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
        this.setState({
          hideNextButton: false,
          filterVisible: !isShowingFilter,
          filterQuestionsVisible: !isShowingFilterQuestions,
          buttonState: false,
        });
      } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
        var allAnswered = false;
        //  
        //  
        var isInvalid = false
        this.state.filterQAnswers.forEach((item,idx) => {
          //  
          if (!item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas.");
        } else {
          this.setState({
            filterQuestionsVisible: !isShowingFilterQuestions,
            ideaQuestionsVisible: !isShowingQuestions,
            buttonState: false,
            formButtonTitle: 'Someter',
          });
        }
      } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
        //  
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Please answer all required questions.");
        } else {
          alert('¡Congrats! Thanks for submitting your idea.', 
          this.resetIdeaForm());
        }
      }
      //  
      //  
      //  
      //  
      //  
    }

    resetIdeaForm() {
      window.location.reload();
    }


    async fetchNextSteps() {
      const { currIdea } = this.props;
      try {
          const tasks = await getTasksByOpportunityId(currIdea.id);
          let activeSteps = 0;
  
          const processedTasks = tasks.map((task) => {
              task.deadline =  task.deadline ? new Date(task.deadline) : null;
              task.completed = task.completedAt !== null; 
              if (!task.completed) {
                  activeSteps += 1;
              }
              return task;
          });
          
          // Filter incomplete tasks
          const incompleteTasks = processedTasks.filter((task) => !task.completed);
          
          // Update state with tasks and active step count
          this.props.setIncompleteTasks(incompleteTasks);
          this.setState({
              taskData: processedTasks,
              activeSteps: activeSteps,
              incompleteTasks: incompleteTasks
          });
      } catch (error) {
          console.error('Error fetching tasks:', error);
          this.setState({
              taskData: [],
              activeSteps: 0,
              incompleteTasks: []
          });
      }
  }
  

    fetchFilterQuestions() {
      const className = "FilterQuestion";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);
      query.find()
      .then((results) => {
        //  
          this.setState({
              filterQuestions: results
          });
      }, (error) => {
          this.setState({
              filterQuestions: []
          });
      });
    }

    handleFilterChange(newFilter) {
      const newCategory = newFilter;
      const { filterQuestions, selectedFilterQ } = this.state;
      
      var filteredData;
      if (newCategory !== 'Todas') {
        filteredData = filterQuestions.filter(item => item.get("filter") === newCategory);
      }  else {
        filteredData = filterQuestions;
      }
      
      this.setState({
        selectedFilterQ: filteredData
      }, this.addFilterAnswer(filteredData));
      //  
      this.showNext();
    }

    handleCategoryChange(selectedCategory) {
      const newCategory = selectedCategory;
      const { ideaQuestions } = this.state;

      var filteredData = ideaQuestions.filter(item => item.get("category") === newCategory );
            
      this.setState({
        categoryQuestions: filteredData
      }, this.addAnswer(filteredData));
    }

    onSubmit = e => {
      e.preventDefault();
      alert('Form submitted');
    };

    saveIdea() {
      // Simple syntax to create a new subclass of Parse.Object.
      var Idea = Parse.Object.extend("Idea");
      // Create a new instance of that class.
      var ideaInfo = new Idea();

      const currentUser = Parse.User.current();
      const userId = currentUser.get("username");
    
      // IDEA Properties
      ideaInfo.set("proponent", userId);
      ideaInfo.set("department",this.state.department);
      ideaInfo.set("category", this.state.category);
      ideaInfo.set("date", this.state.date);
      ideaInfo.set("description", this.state.ideaDescription);
      ideaInfo.set("file", this.state.file);
      ideaInfo.set("status", "saved");
      ideaInfo.set("progress", [0,100]);
      ideaInfo.set("customUpdatedAt", new Date());
      
      // ideaInfo.save()
      // .then((ideaInfo) => {
      //   // Execute any logic that should take place after the object is saved.
      //   this.resetForm();
      //   alert('Su IDEA fue guardada exitosamente.');
      // }, (error) => {
      //   // Execute any logic that should take place if the save fails.
      //   // error is a Parse.Error with an error code and message.
      //   alert('Failed to create new object, with error code: ' + error.message);
      // });
    }

    resetForm() {
      this.setState({proponent: '', department: '', category: '', ideaDescription:'', date: new Date(),file: '', remainingCharacters: 250, descriptionValid:''});
    }

    change(event) {
      // this.setState({category: event.target.value});
      this.setState({department: event.target.value});
      //  
    }

    setCategory(categoryName) {
      this.setState({
        category: categoryName
      })

      this.handleCategoryChange(categoryName)
      //  
    }

    setFilter(filterName) {
      //  
      // this.setState({sectionTitle: (filterName === "innovacion")?"INNOVACION":"RESOLUCION DE PROBLEMAS"});
      this.handleFilterChange(filterName);
    }

    clickedPrint() {
       
    }

    setDate(ideaDate) {
      this.setState({
        date: ideaDate
      })
      //  
    }

    filterQuestionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.filterQAnswers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    questionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.answers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    setIdeaDescription(event) {
      const description = event.target.value;
      const maxCharacters = 250;
      const charCount = maxCharacters - description.length
      var isValid = null;

      if (charCount < 0 && description.length > 0) {
        isValid = false
      } else if (charCount > 0 && description.length > 0) {
        isValid = true
      } else {
        isValid = null
      }

      //  
      this.setState({
        descriptionValid: isValid,
        ideaDescription: description,
        remainingCharacters: charCount
      })
      //  
    }

    selectFile(file) {
      //  
    }

    addAnswer(filteredQuestions) {
      filteredQuestions.map((item, idx) => (
        //  
        this.setState((prevState) => ({
          answers: [...prevState.answers, {question:item.get("question"), answer:"", required: item.get("required")}],
        }))
      ))
      //  
    }

    addFilterAnswer(filteredData) {
      //  
    
      var newItems = [];
      filteredData.forEach((item, idx) => {
        newItems.push({question:item.get("question"), answer:''});
      })

      this.setState({ filterQAnswers: newItems}, () => {
          //  
        });
    }

    submitEvaluation() {
      const {status, ideaDescription, descriptionValid, userName, committeeResObj, executionRes} = this.state;
      const { setFinishedSaving } = this.props;

      var ideaItem = this.props.ideaItem;
      var comments = ideaItem.get("comments");
      var newStatus = '';
      var percentage = [ 25, 75 ];
      var mayNeedEval = false 
      
      // Verify idea to check if it leaves the evaluation inbox or not
      if (status === 'Proyecto' || status === 'Otro') {
        mayNeedEval = true
      }
      

      switch(status) {
        case "Devuelta":
          // code block
          newStatus = 'Idea Devuelta - Mas Informacion';
          break;
        case "Espera":
          // code block
          newStatus = 'Idea en Espera';
          break;
        case "No Perseguido":
          // code block
          newStatus = 'No Perseguido';
          percentage = [0,100];
          break;
        case "Ejecutar":
          // code block
          newStatus = 'Ejecutar - Just Do It';
          break;

        case "Ejecutar Proyecto":
          // code block
          newStatus = 'Ejecutar - Just Do It - Proyecto';
          break;
        case "Proyecto":
          // code block
          newStatus = 'Idea Proyecto';
          percentage = [0,100];
          break;
        default:
          newStatus = 'SOMETIDA';
          percentage = [0,100];
          // code block
      }

      // Comments
      var newComment = {"comment": ideaDescription, "date": new Date(), "user":userName, "progress":[25,75]};

      if (ideaDescription && ideaDescription.length > 0) {
        comments.push(newComment)
      }
       
      const responsibleName = this.state.executionRes.label;

      // if (this.state.coachResObj != '' ) {
      //   const coachName = this.state.coachResObj.label;
      //   const coachUser = this.state.coachResObj.value;
      //   ideaItem.set("coachName",coachName);
      //   ideaItem.set("coach", coachUser);
      // }
     
       
       

      const resUser = this.state.executionRes;

      ideaItem.set("status", newStatus);
      ideaItem.set("comments", comments);
      ideaItem.set("needsEvaluation", mayNeedEval);
      ideaItem.set("progress", percentage);

      if (status == 'Otro') {
        var otherCom = committeeResObj.get('value');
        ideaItem.set("department", otherCom);
      } else {
        ideaItem.set("responsibleName",responsibleName);
      }

      ideaItem.set("responsible", resUser.value);
      ideaItem.set("edited", false);
      ideaItem.set("customUpdatedAt", new Date());
       
      if ( newStatus == 'No Perseguido') {
        if (window.confirm('Did you have a conversation with your employee? If you did, please click OK.')) this.saveIdeaItem(ideaItem) 
      } else {
        this.saveIdeaItem(ideaItem) 
      }
  }

  saveIdeaItem(ideaItem) {
    const { setFinishedSaving } = this.props;
    Parse.Object.saveAll([ideaItem], {useMasterKey: true}).then(() => {
      if (this.state.status == 'Otro') {
        alert('Su evaluacion ha sido sometida. ¡Gracias!', setFinishedSaving());
      } else {
        setFinishedSaving()
      }
    });
  }

    changeStatus(selection) {
      this.setState({status: selection});
       
    }

    // changeResponsible(res, idx) {
    //    
    //   this.setState({
    //     executionResObj: res,
    //     executionRes: idx
    //   });
    //    
    // }

    changeEvaluationResponse() {
      const responseObj = {
        status: '',
        economicImpact: '',
        timeFrame: '',
        recurringImpact: false,
        comments: '',
        ideaOwner: '',
        ideaCoach: '',
        privileges: '',
        supervisor: ''
      }
    }

    async getHumanResourceUsers() {
      var query = new Parse.Query(Parse.User);
      query.equalTo("humanResources", true);
      const results = await query.find();
       
      return results
    }

    async setVerificationNotifications() {
      const allUsers= await this.getHumanResourceUsers();
      for (var u in allUsers) {
        allUsers[u].increment("notificationCount");
        var responsibleNotifications = allUsers[u].get("notificationMessages");
        responsibleNotifications.unshift("'¡Congrats! You have ideas to evaluate.'");
        allUsers[u].set("notificationMessages", responsibleNotifications);
        allUsers[u].set("customUpdatedAt", new Date());
        await allUsers[u].save().catch(error => {
          // This will error, since the Parse.User is not authenticated
        });
      }
    }

    async setNotifications() {
      const {allUsers, department, category} = this.state;

      const responsibleMsg = '¡Congrats! Your idea has been verified.';

      var ideaItem = this.props.ideaItem;

      var responsibleUser = ideaItem.get("responsible");

      var responsibleNotifications = responsibleUser.get("notificationMessages");
      responsibleNotifications.unshift(responsibleMsg);

      responsibleUser.increment("notificationCount");
      responsibleUser.set("notificationMessages", responsibleNotifications);
      responsibleUser.set("customUpdatedAt", new Date());

      var users = new Parse.Query("Users");

      if(this.state.isRecognized) {
        await Parse.Object.saveAll(users, {useMasterKey: true}).then(()=> this.setVerificationNotifications())
        .catch(error => {
           
       });
      } else {
        await Parse.Object.saveAll(users, {useMasterKey: true})
        .catch(error => {
           
       });
      }
    }

    changeBenefit(response) {
        this.setState({
          selectedBenefit: response
        });
      }

    changeResults(response) {
    this.setState({
        selectedResult: response
    });
    }

    changeImprovement(response) {
    this.setState({
        selectedImprovement: response
    });
    }

    changeMoney(response) {
    this.setState({
        money: response
    });
    }

    changeImpact(response) {
    this.setState({
        selectedImpact: response
    });
    }

    changeBackup(response) {
    this.setState({
        selectedCoachBackup: response
    });
    }

    changeMoney(response) {
      const inputMoney = parseFloat(response.target.value);
       
      this.setState({
        money: inputMoney
      })
    }

    changeRecognition(response) {
      this.setState({
          isRecognized: response
      });
      }

    downloadFile(file) {
        //  
        if (file != null) {
            const newWindow = window.open(file._url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else {
            alert('No file found...');
        }
    }

    getDate(date) {
      //  
      return moment(date).format('DD / MM / YYYY');
    }

    getIcon(name, fillColor) {
      const {selectionValue, selectedCategoryName, page} = this.state;

      const newIcons = [
          {normal: <UrgentImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <UrgentImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <ProductivityImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <ProductivityImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <CheckmarkImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <CheckmarkImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <TrophyImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <TrophyImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <Shield2Image className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <Shield2ImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <DollarSignImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <DollarSignImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <NumberOneImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <NumberOneImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
      ]

      switch(name) {
          case 'HandImage':
            return <HandImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ShieldImage':
              return <ShieldImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ChartImage':
              return <ChartImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'TeamImage':
              return <TeamImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'DollarImage':
              return <DollarImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ClockImage':
              return <TimeImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          //New Icons
          case 'Urgent':
              return newIcons[0].selected;
          case 'Productivity':
              return newIcons[1].selected;
          case 'Checkmark':
              return newIcons[2].selected;
          case 'Trophy':
              return newIcons[3].selected;
          case 'Shield':
              return newIcons[4].selected;
          case 'Dollar':
              return newIcons[5].selected;
          case 'Number One':
              return newIcons[6].selected;
          case 'Approve':
              return newIcons[0].selected;
          case 'Do not Pursue':
              return newIcons[1].selected;
          case 'Save for Later':
              return newIcons[2].selected;
          case 'Request information':
              return newIcons[3].selected;
          case 'Project Idea':
              return newIcons[4].selected;
          case 'Transfer Committee':
              return newIcons[5].selected;
          default:
            return <img src={selectIdeaImage} width="200" height="200" />//<SelectIdeaImage className="mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
        }
  }
    toggle() {
      // alert('hover')
      this.setState({
        responseInfo: !this.state.responseInfo
      });
    }

    setEvalStatus(status) {
       
      this.setState({
        selectedStatus: status
      })
    }

    setFirstName(event) {
      const name = event.target.value;
  
      //  
      this.setState({
        firstName: name,
      })
    }

    setEmail(event) {
      const email = event.target.value;
  
      //  
      this.setState({
        email: email,
      })
    }

    changePassword(event) {
      const password = event.target.value;
  
      //  
      this.setState({
        password: password,
      })
    }

    setLanguage(unit) {
       
      this.setState({selectedLanguage: unit}, () => {
        this.loadInitialSettings()
    })
      // this.loadInitialSettings()
      // if (this.state.expectedReturn && unit.label) {
      //   this.props.changeStatus(true)
      // }
    }

    changeSupervisor(res, idx) {
      this.setState({
        supervisor: res,
      });
      
       
    }

    changeDepartment(res, idx) {
        this.setState({
          department: res,
        });
        
        // if (res) {
        //   this.props.changeStatus(true)
        // }
        //  
      }

      changePrivileges(res, idx) {
        this.setState({
          privileges: res?res:[],
        });
         
        // if (res) {
        //   this.props.changeStatus(true)
        // }
        //  
      }

      changeRoles(res, idx) {
        this.setState({
          roles: res?res:[],
        });
         
        // if (res) {
        //   this.props.changeStatus(true)
        // }
        //  
      }


    changeCoach(res, idx) {
      this.setState({
        coachRes: res,
      });
       
    }

    changeLastName(res) {
      const lastName = res.target.value
      this.setState({
        lastName: lastName
      })
    }

    async saveUser() {
      const { firstName, lastName, email, password, privileges, department, supervisor} = this.state;

      var sessionToken = Parse.User.current().getSessionToken();
      var user = new Parse.User();

      if (firstName == '' || lastName == '' || email == '' || department == '') {
        alert('Please enter all required information.');
      } else {

        // Check if super-user
        const isSuperUser = privileges.length !== 0 && privileges.some(e => e.label === 'Super User' || e.label === 'Murmuratto')
        // Check if Coach
        const isCoach = privileges.length !== 0 && privileges.some(e => e.label === 'Coach')
        // Check if Supervisor
        const isSupervisor = privileges.length !== 0 && privileges.some(e => e.label === 'Supervisor')

        // User type
        const userType = isSuperUser? 'super_user':'user'

        // Supervisor email
        const supervisorData = supervisor ? supervisor.value : ""
        const supervisorEmail = supervisorData?supervisorData.get("username"):""
        
         
        const randomPassword = Math.random().toString(36).slice(-8);

          user.set("username", email)
          user.set("password", randomPassword)
          user.set("firstName", firstName)
          user.set("coach", isCoach)
          user.set("isSupervisor", isSupervisor)
          user.set("supervisorEmail", supervisorEmail)
          user.set("lastName", lastName)
          user.set("evaluationCriteria", [])
          user.set("email", email);
          user.set("role", userType)
          user.set("department", department.label)
          user.set("notificationCount", 0)
          user.set("notificationMessages", [])

          try {
              await user.signUp();
              Parse.User.become(sessionToken);
              this.props.refreshUsers()
              // Hooray! Let them use the app now.
              alert('User was created.');
            } catch (error) {
              // Show the error message somewhere and let the user try again.
              alert('There was an error creating user: ' + error.message);
            }
        }
      
     
    }

    async editUser() {
      const { roles, userData, firstName, lastName, email, password, privileges, department, supervisor} = this.state;

      var sessionToken = Parse.User.current().getSessionToken();
      var user = userData;
      
      if (firstName == '' || lastName == '' || email == '' || department == '' ) {
        alert('Please enter all required information.');
      } else {

        if (roles.length > 0) {
          // Check if super-user
          const isSuperUser = roles.some(e => e.label === 'Super User' || e.label === 'Murmuratto')
          // Check if Coach
          const isCoach = roles.some(e => e.label === 'Coach')
          // Check if Supervisor
          const isSupervisor = roles.some(e => e.label === 'Supervisor')

          // User type
          const userType = isSuperUser? 'super_user':'user'

          user.set("isSupervisor", isSupervisor)
          user.set("role", userType)

        } else {
          user.set("role", 'user')
        }
        
        if (privileges.length > 0 ) {
          // Check if super-user
          const isPmo = privileges.some(e => e.label === 'PMO')
          
          // Check if Coach
          const isVerification = privileges.some(e => e.label === 'Verification')
          // Check if Supervisor
          const isReward = privileges.some(e => e.label === 'Reward & Recognition')

          user.set("pmo", isPmo)
          user.set("verification", isVerification)
          user.set("reward", isReward)
        } else {
          user.set("pmo", false)
          user.set("verification", false)
          user.set("reward", false)
        }
        // Supervisor email
        // const supervisorData = supervisor.value
        // const supervisorEmail = supervisorData.get("username")
        //  

          user.set("firstName", firstName)
          user.set("lastName", lastName)
          user.set("email", email);
          user.set("username", email)
          user.set("department", department.label)
          if (password) {
            alert('Password changed!')
            user.set("password", password)
          }
          user.set("customUpdatedAt", new Date());

          // Parse.Object.saveAll([user], {useMasterKey: true})
          Parse.Object.saveAll([user], {useMasterKey: true}).then(() => {
            alert('You user was edited successfully.')
            this.props.refreshUsers()
          }).catch((error) => {
            alert(error.message)
          })

          // try {
          //     await user.signUp();
          //     Parse.User.become(sessionToken);
          //     this.props.refreshUsers()
          //     // Hooray! Let them use the app now.
          //     alert('User was created.');
          //   } catch (error) {
          //     // Show the error message somewhere and let the user try again.
          //     alert('There was an error creating user: ' + error.message);
          //   }
        }
    }

    changeUsername = async() => {
      UserListNew.map((user) => {
        this.changeUserData(user.username, user.email)
      })
    }

    async changeUserData(username, email) {
      var query = new Parse.Query(Parse.User);
      query.equalTo("email", email);
      const results = await query.find();

      if (results.length > 0) {
        const myUser = results[0]
         

        //  
        myUser.set("username", username)
        myUser.set("customUpdatedAt", new Date());
        
        Parse.Object.saveAll([myUser], {useMasterKey: true}).then(() => {
           
          this.props.refreshUsers()
        }).catch((error) => {
           
        })
      }
    }

    

    deleteIdea() {
      const {selectedLanguage, categoryTitle, categoryInformation } = this.state;
      const {ideaStage, evaluationData, categoryData, refreshIdea} = this.props;
      
      const canDelete = window.confirm('Are you sure you want to delete this category?');
      
      if (canDelete) {
        categoryData.destroy().then(() => {
          refreshIdea()
        })
      }
    }

    deleteUser() {
      const {selectedLanguage, categoryTitle, categoryInformation } = this.state;
      const {userData} = this.props;
      
      const canDelete = window.confirm('Are you sure you want to delete this user?');
      
      if (canDelete) {
        userData.destroy({ useMasterKey: true}).then((item) => {
          this.props.refreshUsers()
        })
      }
    }

    createIdea() {

    }

    sendResetEmail() {
      const {userData} = this.props;
      const user = userData
      const userEmail = user.get("email")
      Parse.User.requestPasswordReset(userEmail, {}).then(() => {
        alert('An email has been sent with further instructions.')
      }).catch((error) => {
        alert(error)
      })
    }

    reactivateAccount() {
      const {userData} = this.props;
      const user = userData
      user.set("accountDisabled", false);
      user.set("customUpdatedAt", new Date());

      Parse.Object.saveAll([user], {useMasterKey: true}).then(() => {
        alert('The user was reactivated successfully.')
        this.props.refreshUsers()
      }).catch((error) => {
        alert(error.message)
      })
    }

    addNextStep = async () => {
      const { taskData, activeSteps } = this.state;
      const newTask = {
        completed: false,
        description: 'New task',
        deadLine: null
      };
     
    
      if (activeSteps < 5) {
        try {
          // Use the createTask function from the service
          await createTask(this.props.currIdea.id, null, 'New task');
    
          // Update state with the new task
          const newTaskData = [...taskData, newTask];
          this.setState({ taskData: newTaskData }, async () => {
            
            this.fetchNextSteps();
    
          })
        } catch (error) {
          alert('Failed to create new object, with error code: ' + error.message);
        }
      } else {
        alert('You can only have 5 unfinished tasks at a time.');
      }
    };

   changeTaskCompletion(index) {
      const { taskData } = this.state;
      const taskDataCopy = [...taskData]
      const selectedTask = taskDataCopy[index]

      selectedTask.completed = !selectedTask.completed

      this.setState({taskData: taskDataCopy}, async ()=> {
        this.updateTask(index).then(() => {
          this.fetchNextSteps()
        })
      })
    }

    getSecondaryEmail = async(primaryEmail) => {
      const User = Parse.Object.extend("User");
      const query = new Parse.Query(User);
      query.doesNotExist("deletedAt").equalTo("email", primaryEmail);
      const results = await query.find();
  
      if (results.length >= 1) {
        return results[0].get("secondaryEmail")
      } else {
        return ''
      }
    }

    async logActivityHistory(task) {
     
      const ActivityHistory = Parse.Object.extend("ActivityHistory");
      const activityHistory = new ActivityHistory();
      
      
      activityHistory.set("type", 'Step Completed');
      activityHistory.set("details", task.description);
      activityHistory.set("ideaNum", this.props.currIdea.get("num"));
      activityHistory.set("updatedByName",  this.state.userName);
      activityHistory.set("updatedBy", Parse.User.current().id)
      activityHistory.set("completedAt", new Date())
      activityHistory.set("customUpdatedAt", new Date());
      
      
      activityHistory.save().then(() => {
        // this.props.updateActivityHistory()
        this.props.changeShouldReload(true)
      }).catch((error) => {
        alert(error)
      })
    }

    async logNewActivity() {
      const ActivityHistory = Parse.Object.extend("ActivityHistory");
      const activityHistory = new ActivityHistory();
      
      
      activityHistory.set("type", 'Next Step Added');
      activityHistory.set("details", 'An action item was added to the idea.');
      activityHistory.set("ideaNum", this.props.currIdea.get("num"));
      activityHistory.set("updatedByName",  this.state.userName);
      activityHistory.set("updatedBy", Parse.User.current().id)
      activityHistory.set("completedAt", new Date())
      activityHistory.set("customUpdatedAt", new Date());
      
      
      activityHistory.save().then(() => {
        this.props.changeShouldReload(true)
        return activityHistory;
      }).catch((error) => {
        alert(error)
      })
    }

    async logStepDeletion() {
      const ActivityHistory = Parse.Object.extend("ActivityHistory");
      const activityHistory = new ActivityHistory();
      
      
      activityHistory.set("type", 'Next Step Deleted');
      activityHistory.set("details", 'An action item was removed to the idea.');
      activityHistory.set("ideaNum", this.props.currIdea.get("num"));
      activityHistory.set("updatedByName",  this.state.userName);
      activityHistory.set("updatedBy", Parse.User.current().id)
      activityHistory.set("completedAt", new Date())
      activityHistory.set("customUpdatedAt", new Date());
      
      
      activityHistory.save().then(() => {
        this.props.changeShouldReload(true)
      }).catch((error) => {
        alert(error)
      })
    }

    changeTaskDeadline(newDeadline, index) {
      const { taskData } = this.state;
      const taskDataCopy = [...taskData]
      const selectedTask = taskDataCopy[index]
      
      selectedTask.deadline = newDeadline.setHours(0,0,0,0)

      this.setState({taskData: taskDataCopy}, ()=> {
        this.updateTask(index)
      })
    }

    changeTaskDescription(description, index) {
      const { taskData } = this.state;
      const taskDataCopy = [...taskData]
      const selectedTask = taskDataCopy[index]
     
      selectedTask.description = description.target.value

      this.setState({taskData: taskDataCopy}, ()=> {
        this.updateTask(index)
      })
    }

    async createNewTask() {
      const NextStep = Parse.Object.extend("NextSteps");
      const nextStep = new NextStep();
      
      nextStep.set("description", '');
      nextStep.set("deadline", new Date());
      nextStep.set("ideaNum", this.props.currIdea.get("num"));
      nextStep.set("userId",  Parse.User.Current().id);
      nextStep.set("customUpdatedAt", new Date());
      
      nextStep.save()
      .then((nextStep) => {
        // Execute any logic that should take place after the object is saved.
        // alert('New object created with objectId: ' + nextStep.id);
      }, (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        alert('Failed to create new object, with error code: ' + error.message);
      });
    }

    async updateTask(index) {
      const myTask = this.state.taskData[index];
      const taskData = {
          description: myTask.description,
          deadline: myTask.deadline ? new Date(myTask.deadline) : null,
          completed: myTask.completed,
          customUpdatedAt: new Date(),
      };
      console.log(taskData)
      try {
          const response = await updateTaskById(myTask.id, taskData);
      } catch (error) {
        let errorMessage = 'Failed to update task, with error: ' + error.message;
        if(error.response.data.error)
          errorMessage = error.response.data.error.message;
        else if(error.response.data.message)
          errorMessage = error.response.data.message;

        alert(errorMessage);
      }
  }

    

    deleteNextStep = async (index) => {
      const myTask = this.state.taskData[index];
      const arrCopy = [...this.state.taskData];
      arrCopy.splice(index, 1);
  
      try {
          await deleteTaskById(myTask.id); 
          this.setState({ taskData: arrCopy });
          this.fetchNextSteps();
          alert('Task successfully deleted.');
      } catch (error) {
          console.error('Error deleting task:', error);
          alert('Failed to delete task: ' + (error.response ? error.response.data : error.message));
      }
  };
    render() {
        const { taskData } = this.state
        const percent = this.props.currIdea ? this.props.currIdea.progress : 0

        return(
            <div className="edit-user-details" >
                      <Form className="py-4"
                      onSubmit={this.onSubmit}
                      noValidate
                      >
                        
                        {/* VISIBILITY */}
                        <div >

                          {taskData && taskData.map((task, index) => {
                            const redLabel = this.props.markTasks && this.props.markTasks.find((item) => item.id == task.id)
                            const hasA3 = this.props.currIdea.implement !== null && this.props.currIdea.implement.a3SubmittedAt
                            const status = percent >= 100 || task.completed ? 'green' : 'red'
                             return(<Row form className="ml-2">

                            {percent < 100 && (

                          <Col lg="1" className="mx-auto">
                            <Row form>
                              {/* Proponent */}
                              <Col md="12" className="form-group">
                                
                                <Row className="mt-2">
                                    <Col>
                                    {index === 0 && <label htmlFor="firstName" className="georgia"></label>}
                                        <div style={{marginTop: index === 0 ? 9:0, height: 33, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                              {task.completed ?
                                              <SelectedTaskIcon style={{height: 26, width: 26,}} onClick={() => hasA3?'':this.changeTaskCompletion(index)}></SelectedTaskIcon>
                                              :
                                              <NotSelectedTaskIcon style={{height: 26, width: 26}} onClick={() => hasA3?'':this.changeTaskCompletion(index)}></NotSelectedTaskIcon>
                                              }
                                             
                                        </div>
                                    </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                            )}
                            <Col lg="7" className="mx-auto">
                              <Row form>
                                <Col md="12" className="form-group">
                                    <Row className="mt-2">
                                        <Col>
                                            {index === 0 && <label htmlFor="firstName" className="georgia">Activity Description</label>}
                                            <FormInput
                                            id="categoryName"
                                            value={task.description}
                                            style={{color: redLabel && !task.completed ? 'red' : 'black'}}
                                            onChange={(description) => this.changeTaskDescription(description, index)}
                                            className="insideFont"
                                            disabled={status==='green'}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                              </Row>
                            </Col>

                            <Col lg="2" className="mx-auto">
                              <Row form>
                                <Col md="12" className="form-group">
                                    <Row className="mt-2">
                                        <Col>
                                        {index === 0 &&  <label htmlFor="firstName" className="georgia">Deadline</label>}
                                        <DatePicker
                                            placeholderText={task.deadLine}
                                            dropdownMode="select"
                                            className="text-center"
                                            onChange={(newDate) => this.changeTaskDeadline(newDate, index)} 
                                            selected={task.deadline}
                                            disabled={status==='green'}
                                        />

                                        </Col>
                                    </Row>
                                </Col>
                              </Row>
                            </Col>

                            <Col lg="1" className="mx-auto">
                              <Row form>
                                <Col md="12" className="form-group">
                                    <Row className="mt-2">
                                        <Col className="mx-auto" style={{textAlign: 'center'}}>
                                        {index == 0 &&  <label htmlFor="firstName" className="georgia">Status</label>}
                                           
                                            
                                            <div style={{height: 33, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            {
                                             status==='green' || task.deadline >= +new Date().setHours(0,0,0,0) ?
                                              <GreenIcon style={{height: 20, width: 20}}></GreenIcon> :
                                              <RedIcon style={{height: 20, width: 20}}></RedIcon>
                                            }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                              </Row>
                            </Col>
                            {percent < 100 && (
                            <Col lg="1" className="mx-auto">
                              <Row form>
                                <Col md="12" className="form-group">
                                    <Row className="mt-2">
                                        <Col className="mx-auto" style={{textAlign: 'center'}}>
                                        {index == 0 &&   <label htmlFor="firstName" className="georgia">Delete</label>}
                                            <div style={{height: 33, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <CancelIcon className={hasA3?'':"functionalButton"} style={{height: 34, width: 34}} onClick={() => hasA3?'':this.deleteNextStep(index)}></CancelIcon>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                              </Row>
                            </Col>
                            )}
                        </Row>
                        )
                        })}
                       
                        
                       
                        { percent < 100 && (<Row className="mt-2 ml-2">
                            <Col md="2" className="mr-auto ml-4">
                                <Row>
                                    <AddNextStepIcon className="functionalButton mr-4 d-block mb-4" style={{minWidth: 150, maxWidth:150}} onClick={() => this.addNextStep()}></AddNextStepIcon>
                                </Row>
                            </Col>
                        </Row>) }
    
                       
                       
                        {/* Type of Idea Information (Problema o Innovacion) */}
                        
                        
                      
                        </div>
                        </Form>
                    {/* </CardBody> */}
                    {/* <CardFooter className="border-top">
                      <ButtonGroup size="sm" className="ml-auto d-table mr-3">
                     
                        <Button theme="accent" onClick={() => this.props.onViewIdeaPress()} style={{display: nextButtonVisibilityState}} >{t('BACK')}</Button>
                      </ButtonGroup>
                    </CardFooter> */}
                  </div>
          );
    }
}




export default withTranslation()(NextStepsForm);