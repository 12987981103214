import { any } from "prop-types";
import React from "react";
import { Col, Container, Row } from "shards-react";

import RecognitionTable from "../components/administration/RecognitionTable";
import PageTitle from "../components/common/PageTitle";

import EvaluateIdeaForm from "../components/common/EvaluateIdeaForm";
import RecognitionForm from "../components/common/RecognitionForm";
import { ReactComponent as PreviousIcon } from "../images/PreviousIcon.svg";


import { withTranslation } from 'react-i18next';
import CompletedIdeaFormNew from "./CompletedIdeaFormNew";

class RecognitionInbox extends React.Component { 

  constructor(props) {
    super(props)

    this.state = {
      categoryName: '',
      statusName: '',
      departmentNamee: '',
      open: false,
      ideaItem: any,
      viewIdea: true,
      showPreviousIcon: true,
      showNextIcon: false,
      showSubmitIcon: false,
    }
  }

  onCategoryChange(categoryName) {
     
    this.setState({
      categoryName: categoryName
    })
  }

  onTypeChange(ideaType) {
     
    // var selectedIdeaType = ""
    // if (ideaType == "innovation" || ideaType == 'innovacion') {
    //   selectedIdeaType = "innovacion"
    // } else if (ideaType == "problem solving" || ideaType == 'problema') {
    //   selectedIdeaType = "problema"
    // } else if (ideaType == "continuous improvement" || ideaType == 'mejoras') {
    //   selectedIdeaType = "improvement"
    // }

    this.setState({
      ideaType: ideaType
    })
  }

  onStatusChange(statusName) {
     
    this.setState({
      statusName: statusName
    })
  }

  onDepartmentChange(categoryName) {
    
    var selectedCategoryName = categoryName
    if (categoryName == "All" || categoryName == "Todas") {
      selectedCategoryName = ""
    }

  

    this.setState({
      departmentName: selectedCategoryName
    })

    
  }

  toggle(item) {
     
    this.setState({
      open: true,
      ideaItem: item,
      viewIdea: false
    });
  }

  showIdea(item) {
      this.setState({
      open: true,
      ideaItem: item,
      viewIdea: true,
    });
  }

  dismissModal() {
    this.setState({
      open: !this.state.open,
      viewIdea: false,
    });
  }

  handlePreviousClick = () => {
    // Implement logic for previous button click
    // Example: navigate to the previous step or close modal
    // You can set state or call functions based on your requirements
    // For example:
    this.setState({
      open: false, // Close the modal
      viewIdea: false, // Update view state
    });
  };

  handleNextClick = () => {
    // Implement logic for next button click
    // Example: navigate to the next step or perform an action
    // You can set state or call functions based on your requirements
    // For example:
    this.setState({
      open: true, // Open a modal or proceed to the next step
      viewIdea: true, // Update view state
    });
  };

  handleSubmitClick = () => {
    // Implement logic for submit button click
    // Example: submit form data or perform an action
    // You can set state or call functions based on your requirements
    // For example:
    this.setState({
      open: true, // Open a modal or proceed to the submission process
      viewIdea: false, // Update view state
    });
  };


  render() {
    const {categoryName, statusName, open, viewIdea} = this.state;
    const {t} = this.props;

    return(
      !open?(
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={t("RECOG_IDEA_TITLE")} subtitle={t("WORKFLOW")} className="text-sm-left mb-3" />
        </Row>

        <Row>
          <Col className="mb-4">
            <RecognitionTable category={categoryName} status={statusName} onViewIdeaPress={this.showIdea.bind(this)} onEditActionPress={this.toggle.bind(this)}/>
          </Col>
        </Row>
      </Container>)
  :
  (!viewIdea ? 
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title={t("RECOG_IDEA_TITLE")} subtitle={t("WORKFLOW")} className="text-sm-left mb-3" />
    </Row>
    <Row>
        <Col className="mb-4">
          {this.state.ideaItem.get("completed") == true? 
          <RecognitionForm dismissModal={this.dismissModal.bind(this)} ideaItem={this.state.ideaItem}/>
        :
          <EvaluateIdeaForm dismissModal={this.dismissModal.bind(this)} ideaItem={this.state.ideaItem}/>
        }
        </Col>
    </Row>
    <Row>
        <Col lg="12" className="mb-4 mr-auto">
          {this.state.showPreviousIcon && (
            <PreviousIcon
              className="functionalButton mr-auto d-block"
              style={{ minWidth: 140, maxWidth: 140 }}
              onClick={this.handlePreviousClick}
            ></PreviousIcon>
          )}
        </Col>
      </Row>
  </Container>
  :
     <CompletedIdeaFormNew currIdea={this.state.ideaItem}  closeModal={() => {this.setState({open: false})}} closeA3={() => this.setState({open: false})} stage="verified" customTitle="Recognition"></CompletedIdeaFormNew>

     )
    )
  }
}

export default withTranslation()(RecognitionInbox);

