import React from "react";
import {
    Col,
    Row,
    Tooltip,
} from "shards-react";

import "./LocalStyles/CategorySelectStyle.css";

// Translation
import { withTranslation } from 'react-i18next';

// Default
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as HandImage } from "../../images/hand.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";
import { ReactComponent as ExtraImage } from '../../images/innovation.svg';
import { ReactComponent as ChartImage } from "../../images/line-chart.svg";
import { ReactComponent as ShieldImage } from "../../images/shield.svg";
import { ReactComponent as TeamImage } from "../../images/team.svg";
import { ReactComponent as TimeImage } from "../../images/time.svg";


// New
import { ReactComponent as UrgentImage } from '../../images/Icons_Idle_01_Urgent.svg';
import { ReactComponent as ProductivityImage } from "../../images/Icons_Idle_02_Productivity.svg";
import { ReactComponent as TrophyImage } from "../../images/Icons_Idle_04_Trophy.svg";
import { ReactComponent as Shield2Image } from "../../images/Icons_Idle_05_Shield.svg";
import { ReactComponent as DollarSignImage } from "../../images/Icons_Idle_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImage } from "../../images/Icons_Idle_07_Number One.svg";
import { ReactComponent as CheckmarkImage } from "../../images/check1.svg";

// New Selected
import { ReactComponent as UrgentImageSelected } from '../../images/Icons_Selected_01_Urgent.svg';
import { ReactComponent as ProductivityImageSelected } from "../../images/Icons_Selected_02_Productivity.svg";
import { ReactComponent as TrophyImageSelected } from "../../images/Icons_Selected_04_Trophy.svg";
import { ReactComponent as Shield2ImageSelected } from "../../images/Icons_Selected_05_Shield.svg";
import { ReactComponent as DollarSignImageSelected } from "../../images/Icons_Selected_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImageSelected } from "../../images/Icons_Selected_07_Number One.svg";
import { ReactComponent as CheckmarkImageSelected } from "../../images/check1_selected.svg";
import { fetchAllCategories } from "../../services/tableService";


const selectedColor = '#FD902c'//'#157ffb';//'#ff9012';
const blueColor = '#3A7BBB'
const greenColor = '#7FA86F'
const goldColor = '#DDB153'
const purpleColor = '#62466B'

class CategorySelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectionValue: this.props.selectedValue?this.props.selectedValue:'',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            sq5Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            titleColor5: 'black',
            handIcon: 'black',
            shieldIcon: blueColor,
            chartIcon: greenColor,
            clockIcon: goldColor,
            extraIcon: purpleColor,
            data:[],
            showSecurity:'',
            showQuality:'',
            showProductivity:'',
            showAction:'',
            showExtra:'',
            extraName:'',
            extraValue: '',
            allCats: [],
            bgColor: '',
            right: [],
            selectedCategoryName: ''
          }
          this.fetchCategoryData = this.fetchCategoryData.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedValue !== this.props.selectedValue) {
            this.setState({selectionValue: this.props.selectedValue})
        }
    
    }

    componentDidMount() {
        this.fetchCategoryData();
    }

    fetchCategoryData = async () => {
        const categories = await fetchAllCategories();
        
        this.setState({ allCats: categories, right:[false, false, false, false, false, false, false, false, false, false] });
    }

    changeSelectedValue(selectionValue, category) {
        const myExtraValue = category.title
        this.setState({
            selectionValue: selectionValue,
            selectedCategoryName: category.icon
        })
        // Need category description
        this.props.setCategory(myExtraValue, category.icon, category.description, category.id, category);
        this.props.setSelectedValue(selectionValue)
         
         
    }

    getIcon(name, fillColor) {
        const {selectionValue, selectedCategoryName} = this.state;
        
        const newIcons = [ 
            {normal: <UrgentImage className="functionalButton mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
             selected: <UrgentImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
            },
            {normal: <ProductivityImage className="functionalButton mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
             selected: <ProductivityImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
            },
            {normal: <CheckmarkImage className="functionalButton mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
             selected: <CheckmarkImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
            },
            {normal: <TrophyImage className="functionalButton mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
             selected: <TrophyImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
            },
            {normal: <Shield2Image className="functionalButton mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
             selected: <Shield2ImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
            },
            {normal: <DollarSignImage className="functionalButton mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
             selected: <DollarSignImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
            },
            {normal: <NumberOneImage className="functionalButton mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
             selected: <NumberOneImageSelected className=" mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
            },
        ]

        switch(name) {
            case 'HandImage':
              return <HandImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'ShieldImage':
                return <ShieldImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'ChartImage':
                return <ChartImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'TeamImage':
                return <TeamImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'DollarImage':
                return <DollarImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'ClockImage':
                return <TimeImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            //New Icons
            case 'Urgent':
                return newIcons[0];
            case 'Productivity':
                return newIcons[1];
            case 'Checkmark':
                return newIcons[2];
            case 'Trophy':
                return newIcons[3];
            case 'Shield Image':
                return newIcons[4];
            case 'Dollar':
                return newIcons[5];
            case 'Dollar Sign':
                return newIcons[5];
            case 'Dollar Sign':
                return newIcons[5];
            case 'Number One':
                return newIcons[6];
            default:
              return <ExtraImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80,fill: fillColor}}/>;
          }
    }

    getBgColor(index) {
        if (this.state.selectionValue == index) {
            return selectedColor
        } else {
            return ''
        }
    }

    toggle(index) {
        const myCopy = [...this.state.right]
        myCopy[index] = !myCopy[index]
        this.setState({right: myCopy})
      }
    

    render() {
        const { allCats, titleColor1 } = this.state
        const { t } = this.props;
        const storageLanguage =  localStorage.getItem('language') != null?localStorage.getItem('language'):'en';
        return(
            <div>
                <Row className='pt-2'>
                    {allCats.map((myCategory, index) => { 
                       
                        const isSelected = myCategory.icon == this.state.selectedCategoryName
                        const type = isSelected?'selected':'normal'
                        const icon = this.getIcon(myCategory.icon, 'black')
                        const myIcon = icon[type]
                         
                        const titleLength = myCategory.transcription[storageLanguage] ? myCategory.transcription[storageLanguage].length : 0
                        const lgSetting = titleLength > 20 ? 5 : 3
                        const mdSetting = titleLength > 20 ? 5 : 4
                        const otherLang = storageLanguage === 'en' ? 'es' : 'en'
                        const title = myCategory.transcription[storageLanguage] || myCategory.transcription[otherLang]
                        const myDescription = myCategory.description[storageLanguage] || myCategory.description[otherLang]

                        return(
                        <Col sm="6" lg={lgSetting} md={mdSetting} >
                            <Row>
                                <div className="text-right" >
                                    <div onClick={(event) => { this.changeSelectedValue(index, myCategory)}}>
                                        {myIcon}
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <h6 style={{color: titleColor1, fontWeight: 600, color: '#303030', marginRight: 10}}>{t(title)}</h6>
                                <a id={"TooltipExample" + index} className="text-right" style={{ color: 'inherit'}} onClick={() => {
                                    const myCopy = [...this.state.right]
                                    myCopy[index] = !myCopy[index]
                                    this.setState({right: myCopy})
                                }}>
                                    <InfoIcon style={{height: 10, width: 10, marginTop: 3}}></InfoIcon>
                                </a>
                                <Tooltip
                                    open={this.state.right[index]}
                                    target={"#TooltipExample"+ index}
                                    id={"TooltipExample1"+ index}
                                    toggle={() => {this.toggle(index)}}
                                    >{myDescription}</Tooltip>
                            </Row>
                                
                            {/* </span> */}
                        {/* </div> */}
                        </Col>
                    )})}
                </Row>
            </div>
        )
    }
}

export default withTranslation()(CategorySelect);