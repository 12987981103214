import { saveAs } from 'file-saver';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Col, Container, Row } from "shards-react";
import CategoryTemplate from '../assets/Murmuratto_Template_Category.csv';
import CategoryItem from '../components/administration/CategoryItem';
import ImportDataModal from '../components/administration/ImportDataModal';
import SettingsActionButtons from '../components/common/SettingsActionButtons';
import { ReactComponent as AddCategoryIcon } from "../images/add_category.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import categoryService from "../services/categoryService";
import colors from "../utils/colors";

function AdministrationCategories(smallStats) {
  const [title, setTitle] = useState('')

  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [newCategory, setNewCategory] = useState(false)
  const [activeCategories, setActiveCategories] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [dataCsv, setDataCsv] = useState([])
  const [openCategoryId, setOpenCategoryId] = useState(null);

  const handleToggleCategory = (id) => {
    setOpenCategoryId(openCategoryId === id ? null : id);
  };
  // let currUser = Parse.User.current();
  // this.getUserName()

  const setInitialTitle = async (user) => {
    setTitle('Administration > Categories')
  }

  useEffect(() => {
    // Update the document title using the browser API
    setInitialTitle()
    getCategories()
    // if (newCategory) {
    //   setOpenCategoryId('new-category');
    // }
  }, []);

  const getCategories = async(id = null, loading = true) => {
    setLoading(loading)
    const categories = await categoryService.getCategories();
    const count = categories.filter((cat) => cat.show === true).length;

    setCategories(categories)
    transformDataToTemplate(categories)
    setLoading(false)
    setActiveCategories(count)
  }

  const transformDataToTemplate = (results) => {
    setDataCsv(results)
  }

  const updateCategories = (id = null, loading = true) => {
    setNewCategory('')
    getCategories(id, loading)
  }

  const createCategory = () => {

    const newCategoryObject = {
      id: 'new-category', // O cualquier valor temporal o `null` para un nuevo objeto
      // Añade otras propiedades necesarias para newCategory
    };
    // Actualiza el estado con la nueva categoría
    setNewCategory(newCategoryObject);
    
    // Establece `openCategoryId` para abrir esta nueva categoría
    setOpenCategoryId('new-category');
  }

  const uploadData = () => {
    setOpenModal(true)
  }

  const onFinish = () => {
    alert("Your data was imported succesfully!")
    setOpenModal(false)
    getCategories()
  }


  const  exportData = async () => {
    try {
      const csvData = await categoryService.downloadDataCategories();

      if (!csvData || csvData.length === 0) {
        return;
      }

      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const currentDate = new Date().toISOString().slice(0, 10);
      const fileName = `categories_${currentDate}.csv`;

      saveAs(blob, fileName);

    } catch (error) {
      console.error('Error exporting users:', error);
    }
  }

  return (
  <Container fluid className="main-content-container px-4 pb-4" style={{backgroundColor: 'white'}}>
    <Row>
      <Col md="10" lg="10" className="mt-4 mx-auto">
        <Row>
          <Col md="8" lg="8">
            <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{title}</h3>
          </Col>
          <Col xs="12" md="1" lg="1" className="col d-flex align-items-center ml-auto">
            <SettingsActionButtons uploadData={() => uploadData()} templateFile={CategoryTemplate} exportData={() => exportData()} dataCsv={dataCsv}></SettingsActionButtons>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivisorBarIcon></DivisorBarIcon>
          </Col>
        </Row>
        
      </Col>
      
    </Row>
   {loading ?
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
        <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
      </div>
      :<>


    <Row className="mt-4">
      <Col lg="10" className="m-auto">
        <AddCategoryIcon className="functionalButton mr-4 d-block mb-4" style={{minWidth: 180, maxWidth:180}} onClick={() => createCategory()}></AddCategoryIcon>
      </Col>
    </Row>

    {newCategory && 
      <Row className="mt-2">
        <Col md="12" lg="12">
          <CategoryItem 
            key={'new-category'} 
            id={'new-category'}
            category={newCategory} 
            isNew={true} 
            updateCategories={updateCategories} 
            activeCategories={Number(activeCategories)} 
            isOpen={openCategoryId === 'new-category'} // Compara con el ID temporal
            onToggle={() => handleToggleCategory('new-category')} // Asegura que pueda ser abierto
          />
        </Col>
      </Row>
    }

    { categories && categories.map((category, i) => {
      const isOpen = openCategoryId === category.id;
      return (
        <Row className="mt-2 ">
          <Col md="12" lg="12">
            <CategoryItem key={category.id} id={category.id} category={category} updateCategories={updateCategories} activeCategories={Number(activeCategories)} isOpen={isOpen} onToggle={() => handleToggleCategory(category.id)}></CategoryItem>
          </Col>
        </Row>
      )
    })}
    </>}
    <ImportDataModal open={openModal} onFinish={() => onFinish()} dataType="category"></ImportDataModal>
  </Container>
  )}

AdministrationCategories.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

AdministrationCategories.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default AdministrationCategories;
