import PropTypes from "prop-types";
import React from "react";
import { Navbar, NavbarBrand } from "shards-react";
import { getSubdomain } from '../../../services/userService';

import { Constants, Dispatcher } from "../../../flux";

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  render() {
    const subdomain = getSubdomain() || 'demo';
    const imageUrl = `https://api.murmuratto.com/public/company-logo/${subdomain}?iso=true&_`
    
    return (
      <div className="main-navbar">
        <Navbar
          className="align-items-center bg-white flex-md-nowrap border-bottom p-0"
          type="light"
          style={{ height: "120px", width: 'auto!important' }}
        >
          <NavbarBrand
            className="w-100 mr-0 d-flex justify-content-center align-items-center"
            href={window.location.origin.toString()}
            style={{ lineHeight: "25px", height: "120px" }}
          >
            <div className="d-table m-auto">
           
                <img
                  id="main-logo"
                  className="d-inline-block align-top"
                  style={{ maxWidth: '150px' }}
                  src={imageUrl}
                  alt="Murmuratto"
                />
              </div>
          </NavbarBrand>

          {/* eslint-disable-next-line */}
          <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={this.handleToggleSidebar}
          >
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </Navbar>

      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
