import "bootstrap/dist/css/bootstrap.min.css";
import Parse from 'parse';
import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";

import "./assets/main.scss";
import { analyticsRoutes, defaultRoutes } from "./routes";
import { authUser, checkUpdateMe, updateUser } from "./services/userService";
import withTracker from "./withTracker";

Parse.serverURL = '_';
Parse.initialize('_');
Parse.masterKey = '_';
const currentUser = authUser();

const keepSessionAlive = async () => {
  const currentUser = authUser();
  if (!currentUser) return;
  await checkUpdateMe(currentUser)
};

const App = ({ t, i18n }) => {
  const [loggedIn, setLoggedIn] = useState(currentUser !== null);
  const parseUser = Parse.User.current();
  const isAnalytics = parseUser && parseUser.get('role') === 'analytics';

  const handleAuth = () => {
    setLoggedIn(true);
  };

  const PrivateRoute = ({component: Component, ...rest}) => {
    return (
      <Route {...rest} render={(props) => (
        loggedIn
          ? <Component {...props}/>
          : <Redirect to='/login'/>
      )}/>
    );
  }
  useEffect(() => {
    updateUser();
    const init = async () => {
      await keepSessionAlive();
    };
  
    init();

    // if(process.env.REACT_APP_CLIENT_NAME){
    //   const excludedEmails = ['david@murmuratto.com', 'admin@murmuratto.com','angel@goswiftmedia.com', 'rodrigo@murmuratto.com', 'angel@murmuratto.com', 'angel@lasalle-group.com', 'yolanda.lassalle@lasalle-group.com', 'david@lasalle-group.com'];
    //   if(parseUser && !excludedEmails.includes(parseUser.get('email'))){
    //     (function(h,o,t,j,a,r){
    //       h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    //       h._hjSettings={hjid:4983841,hjsv:6};
    //       a=o.getElementsByTagName('head')[0];
    //       r=o.createElement('script');r.async=1;
    //       r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    //       a.appendChild(r);

    //       const checkHotjar = () => {
    //         if (typeof h.hj !== 'undefined') {
    //           h.hj('identify', parseUser.id, {
    //             userId: parseUser.id,
    //             company: process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo',
    //             role: parseUser.get('role'),
    //             email: parseUser.get('email'),
    //             firstName: parseUser.get('firstName'),
    //             lastName: parseUser.get('lastName'),
    //             dapartment: parseUser.get('dapartment'),
    //             coach: parseUser.get('coach') ? 1 : 0,
    //             dapartmentLeader: parseUser.get('dapartmentLeader') ? 1 : 0,
    //             supervisorEmail: parseUser.get('supervisorEmail'),
    //             commLeader: parseUser.get('commLeader') ? 1 : 0,
    //           });
    //         } else {
    //           setTimeout(checkHotjar, 100);
    //         }
    //       };
    //       checkHotjar();
    //     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    //   }
    // }
  }, []);

  return (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <div>
      {!isAnalytics && defaultRoutes.map((route, index) => {
        if (!route.protected) {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                  <div>
                    <route.component handleAuth={handleAuth} {...props} />
                  </div>
                );
              })}
            />
          );
        } else {
          return (
            <PrivateRoute 
              key={index}
              path={route.path}
              exact={route.exact} 
              component={withTracker(props => {
                return (
                  <div>
                    <route.layout {...props}>
                      <route.component {...props} /> 
                    </route.layout>
                  </div>
                );
              })}/>
          );
        }

      })}
      {isAnalytics && analyticsRoutes.map((route, index) => {
        if (!route.protected) {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                  <div>
                    <route.component handleAuth={handleAuth} {...props} />
                  </div>
                );
              })}
            />
          );
        } else {
          return (
            <PrivateRoute 
              key={index}
              path={route.path}
              exact={route.exact} 
              component={withTracker(props => {
                return (
                  <div>
                    <route.layout {...props}>
                      <route.component {...props} /> 
                    </route.layout>
                  </div>
                );
              })}/>
          );
        }

      })}
    </div>
  </Router>
)};

export default withTranslation()(App)
