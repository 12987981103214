import axios from 'axios';
import moment from 'moment';


import { authUser } from './userService';

const API_URL = 'https://api.murmuratto.com';


export  const getStartDate = async (idea) => { 
  if(!idea) return "N/A";
  const firstAction = await getFirstActionForIdea(idea);
  return firstAction ? moment(firstAction.get('createdAt')).format("DD MMMM YYYY") : "N/A"; 
};

export async function getFirstActionForIdea(idea) {
    if (!idea) return null;
    const ideaReference = idea.num;

    //nose que hace
    let firstAction = null;


    return firstAction;
}

export const submitOpportunity = async (opportunityData) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    const formData = new FormData();
    formData.append("title", opportunityData.title);
    formData.append("description", opportunityData.description);
    formData.append("categoryId", opportunityData.categoryId);
    formData.append("departmentId", opportunityData.departmentId);
    formData.append("typeId", opportunityData.typeId);
    if(opportunityData.fileId)
    formData.append("fileId", opportunityData.fileId);
  
    // Append multiple team members
    if (Array.isArray(opportunityData.team)) {
      opportunityData.team.forEach((teamMember) => {
        formData.append("team", teamMember.id);
      });
    } else {
      formData.append("team", opportunityData.team);
    }
  
    formData.append("expectedReturn", opportunityData.expectedReturn);
    formData.append("filterAnswer", JSON.stringify(opportunityData.filterAnswer));
    formData.append("answer", JSON.stringify(opportunityData.answer));
  
    try {
      const response = await axios.post(`${API_URL}/workflow/submit`, formData, {
        headers: {
          ...authHeader,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error submitting opportunity:', error.response ? error.response.data : error.message);
      throw error;
    }
  };