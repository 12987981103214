import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ReactLoading from 'react-loading';
import { Col, Container, Row } from "shards-react";
import CommTemplate from '../assets/Murmuratto_Template_Committee.csv';
import CommitteeItem from '../components/administration/CommitteeItem';
import ImportDataModal from '../components/administration/ImportDataModal';
import SettingsActionButtons from '../components/common/SettingsActionButtons';
import { ReactComponent as AddCommitteeIcon } from "../images/add_a_committee.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import administrationService from '../services/administrationService'; // Assuming you have a service for API calls
import colors from "../utils/colors";

function AdministrationCommittees(smallStats) {
  const { t } = useTranslation();
  const [title, setTitle] = useState('Administration > Committees');
  const [committees, setCommittees] = useState([]);
  const [newCategory, setNewCategory] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [dataCsv, setDataCsv] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCommittees();
  }, []);

  const getCommittees = async () => {
    try {
      const results = await administrationService.getCommittees();
      setCommittees(results);
      setLoading(false);
      transformDataToTemplate(results);
    } catch (error) {
      console.error('Error fetching committees:', error.message);
    }
  };

  const transformDataToTemplate = (results) => {
    const modifiedData = results.map((idea) => {
      const members = idea.members.map(member => member.label).join(", ");
      const departments = idea.departments.join(", ");
      const categories = idea.categories.join(", ");
      const ideaTypes = idea.ideaTypes.join(", ");

      return {
        "Committee Name": idea.name,
        "Members": members,
        "Departments": departments,
        "Categories": categories,
        "Idea Types": ideaTypes,
      };
    });

    setDataCsv(modifiedData);
  };

  const createCategory = () => {
    setNewCategory({
      name: '',
      value: '',
      evaluationType: '',
      members: '',
      type: '',
      canDelete: true,
      category: '',
      department: '',
      customUpdatedAt: new Date()
    });
  };

  const updateCategories = () => {
    alert('Your committee was updated successfully!');
    setNewCategory('');
    getCommittees();
  };

  const uploadData = () => {
    setOpenModal(true);
  };

  const onFinish = () => {
    alert("Your data was imported successfully!");
    setOpenModal(false);
    getCommittees();
  };

  // Handle downloading the CSV template
  const downloadData = async () => {
    try {
      await administrationService.exportCommittees(); // Call the export service
      
    } catch (error) {
      console.error('Error downloading the CSV file');
      alert('There was an error downloading the CSV file.');
    }
  };

  return (
    <Container fluid className="main-content-container px-4 pb-4" style={{ backgroundColor: 'white' }}>
      
     
      <Row>
        <Col md="10" lg="10" className="mt-4 mx-auto">
          <Row>
            <Col md="8" lg="8">
              <h3 className="m-auto" style={{ fontWeight: 600, color: '#303030' }}>{title}</h3>
            </Col>
            <Col xs="12" md="1" lg="1" className="d-flex align-items-center ml-auto">
              <SettingsActionButtons uploadData={uploadData} templateFile={CommTemplate} dataCsv={dataCsv} exportData={() => downloadData()}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivisorBarIcon></DivisorBarIcon>
            </Col>
          </Row>
        </Col>
      </Row>

      {loading &&
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
        <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
      </div>}


      {!loading && (
        <>
        <Row className="mt-4">
        <Col lg="10" className="m-auto">
          <AddCommitteeIcon className="functionalButton mr-4 d-block mb-4" style={{ minWidth: 180, maxWidth: 180, overflow: 'visible' }} onClick={createCategory} />
        </Col>
      </Row>
      

      {newCategory && (
        <Row className="mt-2">
          <Col md="12" lg="12">
            <CommitteeItem key={Math.random()} id={Math.random()} category={newCategory} isNew={true} updateCategories={updateCategories} />
          </Col>
        </Row>
      )}

      {committees && committees.map((committee, i) => (
        <Row className="mt-2" key={i}>
          <Col md="12" lg="12">
            <CommitteeItem id={i} category={committee} updateCategories={updateCategories} />
          </Col>
        </Row>
      ))}
      </>
      )} 

      <ImportDataModal open={openModal} onFinish={onFinish} dataType="comms" />
    </Container>
  );
}

AdministrationCommittees.propTypes = {
  smallStats: PropTypes.array
};

AdministrationCommittees.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    }
  ]
};

export default AdministrationCommittees;
