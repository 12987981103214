import { saveAs } from 'file-saver';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "shards-react";

import QuestionItem from '../components/administration/QuestionItem';

import colors from "../utils/colors";

import { ReactComponent as AddQuestionIcon } from "../images/add_question.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";

import QuestionsTemplate from '../assets/Murmuratto_Template_Questions_Test.csv';
import QuestionsService from "../services/questionsService";

import { useTranslation } from "react-i18next";
import ImportDataModal from '../components/administration/ImportDataModal';
import SettingsActionButtons from '../components/common/SettingsActionButtons';
import IdeaQuestionsNew from '../components/ecommerce/IdeaQuestionsNew';


function AdministrationQuestions(smallStats) {
  const { t } = useTranslation();
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(1)
  const [category, setCategory] = useState('')
  const [canGoNext, setCanGoNext] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [finishedSaving, setFinishedSaving] = useState(false)
  const [categoryIsOpen, setCategoryIsOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [newCategory, setNewCategory] = useState([])
  const [showUser, setShowUser] = useState(false)
  const [shouldUpdateUsers, setShouldUpdateUsers] = useState(false)
  const [question, setQuestion] = useState('');
  const [openModal, setOpenModal] = useState('');
  const [dataCsv, setDataCsv] = useState([]);

  // this.getUserName()

  const setInitialTitle = async (user) => {
    
    // return firstName
   
    setTitle('Administration > Questions')

  }

  useEffect(() => {
    // Update the document title using the browser API
    setInitialTitle()
    getQuestions()
  }, []);

  const getQuestions = async() => {
    const questions = await QuestionsService.getQuestions();
    const count = questions.filter((cat) => cat.show === true).length;

    setQuestion(questions)
  }

  const  exportData = async () => {
    try {
      const csvData = await QuestionsService.downloadDataQuestions();

      if (!csvData || csvData.length === 0) {
        return;
      }

      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const currentDate = new Date().toISOString().slice(0, 10);
      const fileName = `questions_${currentDate}.csv`;

      saveAs(blob, fileName);

    } catch (error) {
      console.error('Error exporting users:', error);
    }
  }

  const changeIdeaStage = () => {
    const newStage = ideaStage + 1
    
    if (newStage == 0) {
      setTitle('Welcome back, ' + username+ '!')
      setIdeaStage(newStage)
    } else if (newStage == 1) {
      setTitle(t('Opportunity >') + ' Select Idea Type')
      setIdeaStage(newStage)
    } else if (newStage == 2) {
      setTitle(t('Opportunity >') + ' Innovation > Idea Details')
      setIdeaStage(newStage)
    } else if (newStage == 3) {
      setTitle(t('Opportunity >') + ' Innovation > Idea Details > Done')
      setCanSubmit(true)
    } else {
      setTitle('Welcome back, ' + username+ '!')
      setIdeaStage(0)
    }
  }


  const updateQuestions = () => {
    setShouldUpdateUsers(!shouldUpdateUsers)
    setShowUser(false)
    setQuestion('')
  }

  const createUser = () => {
    setQuestion('')
    setShowUser(true)
  }

  const editItem = (item) => {
    // alert('edit me')
     
    setQuestion(item)
    setShowUser(true)
  }

  const uploadData = () => {
    setOpenModal(true)
  }

  const onFinish = () => {
    alert("Your data was imported succesfully!")
    setOpenModal(false)
    setShouldUpdateUsers(!shouldUpdateUsers)
    setShowUser(false)
    setQuestion('')
    // getCategories()
  }

  return (
  <Container fluid className="main-content-container px-4" style={{backgroundColor: 'white'}}>
    <Row>
      {/* Page Header :: Title */}
      <Col md="10" lg="10" className="mt-4 mx-auto">
        {/* <PageTitle title={t('Welcome back, Angel')} subtitle=" " className="text-sm-left" /> */}
        <Row>
          <Col md="8" lg="8">
            <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{title}</h3>
          </Col>
          
          <Col xs="12" md="1" lg="1" className="col d-flex align-items-center ml-auto">
            {/* <Button pill onClick={() => uploadData()}>Upload Data</Button> */}            
            <SettingsActionButtons uploadData={() => uploadData()} templateFile={QuestionsTemplate} exportData={() => exportData()} dataCsv={dataCsv}></SettingsActionButtons>

            {/* <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
              <Button theme="white" tag={NavLink} to="/submit-idea">
                {t('SUBMIT')}
              </Button>
              <Button theme="white" tag={NavLink} to="/search-idea">
                {t('SEARCH')}
              </Button>
            </ButtonGroup> */}
           
          </Col>
        </Row>
        <Row>
          <Col>
            <DivisorBarIcon></DivisorBarIcon>
          </Col>
        </Row>
      </Col>
      {/* Page Header :: Actions */}
      
    </Row>
    
    {/* Divider */}
    {/* <Col xs="12" md="12" style={{height: 1, width: '100%', backgroundColor: 'black', opacity: 0.2}} className="col d-flex align-items-center"></Col> */}

    {/* Categories */}

    
    
    <Row className="mt-4">
      <Col lg="10" className="m-auto">
       
        <AddQuestionIcon className="functionalButton mr-4 d-block mb-4" style={{minWidth: 180, maxWidth:180}} onClick={() => createUser()}></AddQuestionIcon>

        {/* {ideaStage == 0 && canGoNext && <SubmitIcon className="ml-auto d-block" style={{minWidth: 140, maxWidth:140}} onClick={() => changeIdeaStage()}></SubmitIcon>}
        {ideaStage == 0 && !canGoNext && <SubmitIconNosel className="ml-auto d-block" style={{minWidth: 140, maxWidth:140}} ></SubmitIconNosel>}
        

        {ideaStage > 0 && ideaStage <= 2 && canContinue &&  <NextIconValid className="ml-auto d-block mb-4" style={{minWidth: 140, maxWidth:140}} onClick={() => changeIdeaStage()}></NextIconValid>}
        {ideaStage > 0 && ideaStage <= 2 && !canContinue && <NextIcon className="ml-auto d-block mb-4" style={{minWidth: 140, maxWidth:140}} ></NextIcon>}
        
        {ideaStage == 3  && <HomeIcon className="ml-auto d-block mb-4" style={{minWidth: 90, maxWidth:90}} onClick={() => changeIdeaStage()}></HomeIcon>} */}
      </Col>
    </Row>

    <Row className="mt-2">
        <Col md="12" lg="12">
        { showUser && <QuestionItem key={Math.random()} id={Math.random()}  updateQuestions={updateQuestions} question={question}></QuestionItem>}
        </Col>
    </Row>

    <Row className="mt-2">
        <Col lg="10" className="m-auto">
            <div style={{backgroundColor: '#F6F6F6', height: 500, overflow: 'scroll', borderStyle: 'solid', borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 100}}>
                <IdeaQuestionsNew shouldUpdate={shouldUpdateUsers} editItem={(question) => editItem(question)} setDataCsv={(data) => setDataCsv(data)}/>
            </div>
        </Col>
    </Row>
        
    <ImportDataModal open={openModal} onFinish={() => onFinish()} dataType="questions"></ImportDataModal>
    
  </Container>
  )}

AdministrationQuestions.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

AdministrationQuestions.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default AdministrationQuestions;
