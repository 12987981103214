import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import { Link, Redirect, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  FormInput,
  Row
} from "shards-react";
import { sendResetEmail } from '../services/emailService';
import { authUser, getSubdomain } from "../services/userService";


const Register = (props) => {
  const currentUser = authUser();
  if(currentUser) return <Redirect to="/submit" />;
  
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const subdomain = getSubdomain() || 'demo';

  const changeEmail = (event) => {
    const email = event.target.value.toLowerCase();
    setEmail(email)
  }

  const sendResetEmailHandler = async () => {
    setLoading(true);
  
    const isEmailValid = isValidEmailFormat(email);
    if (isEmailValid) {
      try {
      
        await sendResetEmail(email, subdomain);
        setLoading(false);
        props.history.push('/login');
      } catch (error) {
        setLoading(false);
        alert('There was an error processing your request. Please try again later.');
        console.error('Error:', error);
      }
    } else {
      alert('Please enter a valid email.');
      setLoading(false);
    }
  };

  const isValidEmailFormat = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  const onFormSubmit = e => {
    e.preventDefault();
    sendResetEmailHandler()
  }
  
  return(
  <Container fluid className="main-content-container h-200 px-4">
    <Row noGutters className="h-200 flex-center">
      <Col lg="3" md="5" className="auth-form mx-auto my-auto flex-center">
        <Card className="mt-4">
          <CardBody>
            <h5 className="auth-form__title text-center mb-4">
              Forgot Password
            </h5>

            <Form onSubmit={onFormSubmit}>
              <FormGroup>
                <label htmlFor="exampleInputEmail1">Email address</label>
                <FormInput
                  type="email"
                  id="exampleInputEmail1"
                  placeholder="Enter email"
                  autoComplete="email"
                  value={email}
                  onChange={(event) => changeEmail(event)}
                />
                <small className="form-text text-muted text-center">
                  You will receive an email with a unique token and link.
                </small>
              </FormGroup>
              
              {!loading?
                <Button
                  pill
                  disabled={email.length == 0}
                  submit
                  theme="accent"
                  className="d-table mx-auto"
                  onClick={() => sendResetEmailHandler()}
                >
                  Reset Password
                </Button>
              :
              <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '3em'}}>
                <ReactLoading type={'spokes'} color={'#633FDA'} width={40} height={40}/>
              </div>
              }
            </Form>
          </CardBody>
        </Card>

        <div className="auth-form__meta d-flex mt-4">
          <Link to="/login" className="mx-auto">
            Take me back to login.
          </Link>
        </div>
      </Col>
    </Row>
  </Container>
)};

export default withRouter(Register);
