import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Col, Row } from "shards-react";
import { ReactComponent as ArrowDownIcon } from "../../images/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/arrow_up.svg";
import colors from "../../utils/colors";
import EditCommitteeForm from '../common/EditCommitteeForm';


function CommitteeItem({category, updateCategories, isNew = false}) {
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(1)
//   const [category, setCategory] = useState('')
  const [canGoNext, setCanGoNext] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [categoryIsOpen, setCategoryIsOpen] = useState(isNew)

  const setInitialTitle = async (user) => {
    setTitle('Administration > Categories')

  }

  useEffect(() => {
    setInitialTitle()
  });


  const setFinished = () => {
   setCategoryIsOpen(!categoryIsOpen)
  }

  const changeBtnStatus = (status) => {
   
   setCanGoNext(status)
  }

  const changeContinueBtnStatus = (status) => {
    
   setCanContinue(status)
  }

  
  // let deptName = '';
  // if (category && category.departments && category.departments.length >= 1) {
  //   deptName = 'Multiple Departments';
  // } else if (category && category.departments && category.departments.length === 1) {
  //   deptName = category.departments[0].title;
  // }


  const committeePre = category.title ? category.title : 'New Committee'
  // const postName = deptName?" - ["+deptName+"]":'' + postName
  const committeeName = committeePre
  
  return (
    <Row>
      {/* Latest Orders */}
      <Col lg="10" className="m-auto">
              <div className="my-auto" style={{width: '100%', borderStyle: 'solid',borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 5, display: 'flex'}}>

                <div className="ml-4 my-auto" style={{height: '100%', display: 'inline-block'}}>
                    <h6 className="my-auto" style={{fontWeight: 500,  color: '#303030',}}>{committeeName?committeeName:"New Committee"}</h6>
                </div>

                { categoryIsOpen && <ArrowUpIcon onClick={() => setCategoryIsOpen(false)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowUpIcon> }
                { !categoryIsOpen && <ArrowDownIcon onClick={() => setCategoryIsOpen(true)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowDownIcon>}
              
              </div>
              { categoryIsOpen &&
              <div style={{backgroundColor: '#F6F6F6',}}>
               <EditCommitteeForm refreshIdea={updateCategories} categoryData={category} canSubmit={canSubmit} setFinishedSaving={() => setFinished()} ideaStage={ideaStage} changeStatus={(status) => changeBtnStatus(status)} changeContinueStatus={(status) => changeContinueBtnStatus(status)} changeIdeaStage={() => this.changeIdeaStage()}/>
              </div>
              }
      </Col>
    </Row>
  )}

CommitteeItem.propTypes = {
  smallStats: PropTypes.array
};

CommitteeItem.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default CommitteeItem;
