import React from "react";
import { withTranslation } from "react-i18next";
import ReactLoading from 'react-loading';
import ReactTable from "react-table";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { fetchHistory } from "../services/evaluateService";
import MyIdeaInfo from "./MyIdeaInfo";


class TransactionHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      tableData: [],
      viewIdea: false,
      isLoading: false,
      ideaItem: '',
      data: '',
    };

    this.searcher = null;

    this.getStatusClass = this.getStatusClass.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handleItemConfirm = this.handleItemConfirm.bind(this);
    this.handleItemViewDetails = this.handleItemViewDetails.bind(this);
  }

  componentWillMount() {
    this.fetchIdeas();
  }

  // LOAD DATA
  async fetchIdeas() {
    const {isLoading} = this.state;
    if (isLoading) return;
    this.setState({ isLoading: true });
   const opportunities = await fetchHistory();
    this.setState({ isLoading: false });
   
      var myData = opportunities.map(function (idea) {
        var newData = {...idea, };
        newData['objectId'] = idea.id;
        newData['id'] = idea.num;
        newData['merged'] = idea.is.merged;
        newData['duplicate'] = idea.is.duplicate;
        newData['desc'] = idea.title;
        newData['cat'] = idea.category;
        newData['prop'] = idea.proponent; 
        newData['status'] = idea.status; 
         
        return newData
      })
      
        this.setState({
            tableData: myData,
            data: myData
        }, this.initSearcher);
    
  }

  /**
   * Returns the appropriate status class for the `Status` column.
   */
  getStatusClass(status) {
    const statusMap = {
      Cancelled: "danger",
      Complete: "success",
      Pending: "warning"
    };

    return `text-${statusMap[status]}`;
  }

  /**
   * Handles the page size change event.
   */
  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }

  /**
   * Handles the global search.
   */
  handleFilterSearch(e) {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ search: searchTerm }, () => {
      const tableData = this.state.data.filter(idea => {
        if(idea.num === Number(searchTerm)) return true;
        return idea.title.toLowerCase().includes(searchTerm) ||
        idea.category.toLowerCase().includes(searchTerm) ||
        idea.status.toLowerCase().includes(searchTerm)
      });
      this.setState({ tableData });
    });
  }
  /**
   * Mock method for editing transactions.
   */
  handleItemEdit(row) {
    alert(`Editing transaction "${row.original.id}"!`);
  }

  /**
   * Mock method for deleting transactions.
   */
  handleItemDelete(row) {
    alert(`Deleting transaction "${row.original.id}"!`);
  }

  /**
   * Mock method for confirming transactions.
   */
  handleItemConfirm(item) {
    const ideaItem = this.state.data.find(x => x.id === item.objectId);
    this.setState({
      ideaItem,
      viewIdea: !this.state.viewIdea
    })
  }

  async deleteIdea(row) {
    const idea = this.state.data[row.index];
    try {
        await idea.destroy();
        // Optional: Update your local state to reflect the deletion
        // const updatedData = [...this.state.data];
        // updatedData.splice(row.index, 1);
        // this.setState({ data: updatedData });

    } catch (error) {
        console.error('Error while deleting idea: ', error);
    }
}


  /**
   * Mock method for confirming transactions.
   */
  handleItemViewDetails(row) {
    alert(`Viewing details for "${row.original.id}"!`);
  }

  dismissModal() {
    this.setState({
      viewIdea: !this.state.viewIdea
    });
  }

  render() {
    const { tableData, pageSize, isLoading, pageSizeOptions, viewIdea } = this.state;
    const {t} = this.props;
    const tableColumns = [
      {
        Header: "#",
        accessor: "id",
        maxWidth: 60,
        className: "text-center"
      },
      {
        Header: t("TRANSACTION_DESCRIPTION"),
        accessor: "desc",
        className: "text-center",
        style: { 'white-space': 'unset' }, 
        minWidth: 150,
        Cell: row => {
          return <> 
            {row.original.desc}
            {row.original.merged &&
              <Badge pill style={{marginLeft: '10px', backgroundColor: '#643fda', color: 'white'}}>
                Merged
              </Badge>
            }
          </>
        },
      },
      {
        Header: t("TRANSACTION_CATEGORY"),
        accessor: "cat",
        maxWidth: 150,
        style: {color: '#17c671','white-space': 'unset'},
        className: "text-center"
      },
      {
        Header: t("TRANSACTION_PROPONENT"),
        accessor: "prop",
        maxWidth: 150,
        style: { 'white-space': 'unset' },
        className: "text-center"
      },
      {
        Header: t("TRANSACTION_STATUS"),
        accessor: "status",
        maxWidth: 120,
        style: { 'white-space': 'unset' },
        Cell: row => (
          <Badge pill style={{whiteSpace: 'unset'}} theme={getBadgeType(row.original.status)}>
            {row.original.status === "SOMETIDA" ? "Submitted" : row.original.status}

          </Badge>
          // <span className={this.getStatusClass(row.original.status)}>
          //   {row.original.status}
          // </span>
        ),
        className: "text-center"
      },
      {
        Header: 'Action',
        accessor: "actions",
        width: 80,
        sortable: false,
        Cell: row => (
          <Badge pill outline theme="secondary" style={{cursor:'pointer'}} onClick={() => { this.handleItemConfirm(row.original) }}>View</Badge>
        )
      },
    ];

    return (
      viewIdea == false ? <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={t("TRANSACTION_EVALUATED")} subtitle={t("TRANSACTION_MANAGE")} className="text-sm-left mb-3" />
        </Row>
        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="6">
                  <span>{t('SHOW')}</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size + ' ' + t('ROWS')} 
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="6">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
            {isLoading ? (
              <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
              </div>
            ) : (
              <ReactTable
                columns={tableColumns}
                data={tableData}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
              />)}
            </div>
          </CardBody>
        </Card>
        
      </Container>
      :
      <MyIdeaInfo from="evaluate" type={'viewIdea'} currIdea={this.state.ideaItem} updateCurrIdea={() => this.updateCurrIdea()} closeModal={() => this.setState({viewIdea: false})} source={'your-ideas'}></MyIdeaInfo>
    );
  }
}

function getBadgeType(itemStatus) {
  const statusMap = {
    Complete: "success",
    Saved: "warning",
    Canceled: "danger"
  };

  return statusMap[itemStatus];
}

export default withTranslation()(TransactionHistory);
