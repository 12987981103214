import React from "react";
import { Nav } from "shards-react";

import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { Store } from "../../../flux";
import MurmurattoLogo from '../../../images/Murmuratto_With-Icon_Purple.png';
import { authUser } from "../../../services/userService";
import SidebarNavItem from "./SidebarNavItem";


class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems(),
      type: '',
      auth: null,
      isLoading: true
    };
    
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    Store.addChangeListener(this.onChange);
    try {
      const authData = await authUser();
      if (!authData) {
        this.handleUnauthorized();
      } else {
        this.setState({ auth: authData, isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching auth data:", error);
      this.handleUnauthorized();
    }
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      navItems: Store.getSidebarItems()
    });
  }

  handleUnauthorized = () => {
    this.props.history.push("/login");
  }

  render() {
    const currentUser = authUser();

    const { navItems: items, auth, isLoading } = this.state;
    if (isLoading) {
      return <></>;
    }

    return (
      <div className="nav-wrapper">
        {items.map((nav, idx) => (
          <div key={idx}>
            <span style={{
               marginTop: 10,
               margin: '0',
               fontSize: '0.8em',
               padding: '1px 1.5625rem',
             }}> {currentUser.company.name || 'Murmuratto'}</span>
           <h6 className="main-sidebar__nav-title">{nav.title}</h6>
           {typeof nav.items !== "undefined" && nav.items.length && (
             <Nav className="nav--no-borders flex-column">
               {nav.items.map((item, idx) => {
                 if (item.roles.includes('deptLeader') && (auth.departmentLeader || auth.commLeader)) {
                   return <SidebarNavItem key={idx} item={item} />
                 }
                 if (item.roles.includes('enterprise') && auth.isEnterprise) {
                   return <SidebarNavItem key={idx} item={item} />
                 }
                 if (item.roles.includes('verification') && auth.verification) {
                   return <SidebarNavItem key={idx} item={item} />
                 }
                 if (item.roles.includes(auth.role)) {
                   return <SidebarNavItem key={idx} item={item} />
                 }
               })}
             </Nav>
           )}
          </div>
        ))}
        <div className="d-table m-auto" style={{textAlign:'center'}}>
          <br/>
          <div className="onlyMobile">
            <h6 className="main-sidebar__nav-title" style={{textAlign:'center'}}>Mobile Version</h6>
            <small>Access Murmuratto from your computer's browser to access additional workflows and functionalities.</small>
            <br/>
            <br/>
            <br/>
          </div>
          <small style={{textAlign:'center'}}>powered by</small>
          <br/>
          <a href="http://www.murmuratto.com/" rel="noopener noreferrer" target="_blank">
            <img
              id="main-logo"
              className="d-inline-block align-top mr-1 ml-3"
              style={{ maxWidth: "120px" }}
              src={MurmurattoLogo}
              alt="Murmuration"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(SidebarNavItems));
