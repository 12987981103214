import axios from 'axios';
import { authUser } from './userService';

const API_URL = 'https://api.murmuratto.com';

const fetchSites = async () => {
  try {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const response = await axios.get(`${API_URL}/table/companies-enterprise`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching opportunity ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const fetchReports = async (companies, date, report = false) => {
  try {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const response = await axios.get(`${API_URL}/reports/enterprise`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      },
      params: {
        companies,
        export: report,
        start: date ? date.startDate : null,
        end: date ? date.endDate : null,
      },
      paramsSerializer: params => {
        return params ? Object.keys(params).map(key => 
        {
          if(Array.isArray(params[key]) && params[key].length === 0) return '';
          if(Array.isArray(params[key]) && params[key].length > 0) return params[key].map(value => `${key}=${encodeURIComponent(value)}`).join('&')

          if(params[key] !== null && params[key] !== undefined)
            return `${key}=${encodeURIComponent(params[key])}`
          
          return '';
        }
        ).filter((d)=>d).join('&') : '';
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching opportunity ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export {
  fetchReports, fetchSites
};

